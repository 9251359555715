<template>
  <div>
    <!-- form -->
    <FormLoginAiops>
      <template #content-login>
        <validation-observer
          ref="aiopsLogin"
          @keyup.enter="validationForm"
        >
          <b-form>
            <b-row class="py-2 px-2">
              <b-col
                class="col-sm-12 col-md-3"
              >
                <img
                  class="d-block aiops_img_login"
                  :src="require('@/assets/images/aiops/logo/IllustrationHeandphones.svg')"
                  alt="image slot"
                >
              </b-col>

              <!-- password -->
              <b-col
                class="col-sm-12 col-md-9 pl-4"
              >
                <b-row>
                  <b-col md="12">
                    <h3
                      class="aiops_mineral_green_heading3"
                      style="text-transform: uppercase;"
                    >
                      {{ $t('Sign In') }}
                      <!-- SIGN IN -->
                    </h3>
                    <span
                      class="aiops_neutral_blue_heading1"
                      style="text-transform: uppercase;"
                    >
                      {{ $t('Please Insert Your Credentials') }}
                    </span>
                  </b-col>
                  <b-col md="6">
                    <b-row class="pt-2">
                      <b-col md="12">
                        <b-form-group
                          class="aiops_label_form_bold"
                        >
                          <label
                            for="user"
                            class="aiops_label_form_bold"
                          >
                            {{ $t('User') }}
                          </label>
                          <validation-provider
                            #default="{ errors }"
                            name="user"
                            rules="required"
                          >
                            <b-form-input
                              id="user"
                              v-model="user"
                              autocomplete="off"
                              :state="errors.length > 0 ? false:null"
                              :placeholder="$t('User')"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                        <b-form-group
                          class="aiops_label_form_bold"
                        >
                          <label
                            for="password"
                            class="aiops_label_form_bold"
                          >
                            {{ $t('Password') }}
                          </label>
                          <validation-provider
                            #default="{ errors }"
                            name="Password"
                            rules="required"
                          >
                            <b-form-input
                              id="passwordLogin"
                              v-model="passValue"
                              autocomplete="off"
                              type="password"
                              :state="errors.length > 0 ? false:null"
                              :placeholder="$t('Password')"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <!-- checkbox -->
                      <b-col md="6">
                        <b-form-group>
                          <b-form-checkbox
                            v-model="selected"
                            value="I"
                            class="custom-control-ibop"
                          >
                            <label
                              class="aiops_label_form"
                            >
                              {{ $t('Remember Me') }}
                            </label>
                          </b-form-checkbox>
                        </b-form-group>
                      </b-col>
                      <b-col
                        md="5"
                        offset-md="1"
                      >
                        <p
                          class="aiops_mineral_green_label_form text-right"
                        >
                          <a
                            class="aiops_login_forgotPassword"
                            href="http://"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {{ $t('Forgot Password') }}
                          </a>
                        </p>
                      </b-col>
                    </b-row>
                    <!-- login button -->
                    <b-row align-h="end">
                      <b-col
                        class="text-right"
                        md="4"
                      >
                        <b-button
                          class="ibop-persian-green-button"
                          @click="validationForm"
                        >
                          <span class="align-middle aiops_label_white_14">{{ $t('Login') }}</span>
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </template>
    </FormLoginAiops>
  </div>
</template>

<script>
// import jwtDefaultConfig from '@core/auth/aiops/jwtDefaultConfig'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BCardText, BFormCheckbox,
} from 'bootstrap-vue'
import { password, required } from '@validations'
import store from '@/store'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import FormLoginAiops from '../../../../forms/form-validation/form-login-aiops/FormLoginAiops.vue'

export default {
  components: {
    BCardText,
    FormLoginAiops,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormCheckbox,
  },
  data() {
    return {
      passValue: '',
      user: '',
      required,
      password,
      selected: false,
    }
  },
  created() {
    this.welcomeAssist()
    this.logout()
    setTimeout(() => {
      this.inActiveLoading()
    }, 1000)
  },
  methods: {
    validationForm() {
      this.$refs.aiopsLogin.validate().then(success => {
        if (success) {
          this.activeLoading()
          const payloadLogin = {
            user: this.user,
            password: this.passValue,
          }
          store.dispatch('productAiops/login', payloadLogin)
            .then(response => {
              const dataLogin = response
              if (dataLogin.data.ability) {
                this.$ability.update(dataLogin.data.ability)
              }
              // this.$router.push({ name: 'aiops-home' })
              if (store.state.productAiops.refreshToken) {
                setTimeout(() => {
                  this.refreshToken()
                }, dataLogin.timeToCall * 1000)
              }
              this.$router.replace(getHomeRouteForLoggedInUser(dataLogin.data.roles))
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `Welcome ${this.user}`,
                      icon: 'mintui_TicketEnrichment_Icon_White',
                      variant: 'success',
                      text: 'You have successfully logged in. Now you can start to explore!',
                    },
                  })
                })
            })
            .catch(error => {
              this.inActiveLoading()
              store.dispatch('productAiops/errorRequest')
              this.$refs.aiopsLogin.setErrors(error)
              this.errorMessage()
            })
        } else {
          this.inActiveLoading()
          this.errorMessage()
        }
      })
    },
    refreshToken() {
      store.dispatch('productAiops/refreshToken')
        .then(res => {
          const timeToCall = res - 2
          if (store.state.productAiops.refreshToken) {
            setTimeout(() => {
              this.refreshToken()
            }, timeToCall * 1000)
          }
        })
        .catch(() => {
          store.dispatch('productAiops/errorRequest')
        })
    },
    errorMessage() {
      const variant = 'warning'
      this.$bvToast.toast('Your username or password is invalid.', {
        title: 'TEIS Login',
        variant,
        solid: true,
      })
    },
    logout() {
      store.dispatch('productAiops/errorRequest')
    },
    welcomeAssist() {
      store.dispatch('productAiops/showWelcomeAssist', false)
    },
    activeLoading() {
      const appLoading = document.getElementById('loading-bg')
      if (appLoading) {
        appLoading.style.display = 'block'
      }
    },
    inActiveLoading() {
      const appLoading = document.getElementById('loading-bg')
      if (appLoading) {
        appLoading.style.display = 'none'
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.backLogin {
  background: #ddedee;
}

.aips-form-login {
  position: relative;
}

.aiops_login_forgotPassword {
  color: rgba(0, 170, 155, 1);
  font-family: "Open Sans", Helvetica, Arial, serif;
  font-size: 0.77vw;
  font-style: normal;
  font-weight: 400;
}

.aiops_img_login {
  max-width: 14.5vw;
  max-height: 15.2vw;
  width: auto;
  height: auto;
}
</style>
