<template>
  <!-- form -->
  <div class="aips-form-login backLogin">
    <b-container
      fluid
      class="aiops_container"
    >
      <slot name="content-login" />
    </b-container>
  </div>
</template>

<script>
import {
  BContainer,
} from 'bootstrap-vue'

export default {
  name: 'FormLoginAiops',
  components: {
    BContainer,
  },
  data() {
    return {
    }
  },
  methods: {
  },
}
</script>

<style lang="scss" scoped>
.backLogin {
  background: #ddedee;
}

.aips-form-login {
  position: relative;
}

.aiops_container {
  display: flex;
  align-items: center;
  min-height: 41.44vw;
  justify-content: center;
}

</style>
